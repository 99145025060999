<template>
  <div class="elite-tabs-wrapper-content">
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="contacts"
          @sort-change="sortChange"
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>
          <el-table-column
            :label="$t('COMMON.NAME')"
            prop="code"
            sortable="custom"
            min-width="200"
          >
            <template v-slot="{ row }">
              <span v-if="row?.firstname">
                {{ `${row.firstname} ${row.lastname}` }}
              </span>

              <span v-if="row.company_name">
                {{ `${row.company_name}` }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.EMAIL')"
            prop="email"
            min-width="220"
          />
          <el-table-column
            :label="$t('COMMON.PHONE')"
            prop="phone"
            min-width="180"
          />
          <el-table-column
            :label="$t('AUCTIONS.TOTAL_PROPERTIES')"
            min-width="75"
          >
            <template v-slot="{ row }">
              <span class="text-center">
                {{ calculateTotal(row.properties) }}
              </span>
            </template>
          </el-table-column>

          <el-table-column min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_CONTACTS)"
              >
                <a
                  type="text"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <router-link :to="$objectViewRoute(row)">
                    <i class="fal fa-expand-alt"></i>
                  </router-link>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}
        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import Tags from "@/components/Tags.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";

export default {
  name: "property-contact-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    Tags,
    TagsSelector,
    LocationsSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterProperty: {
      type: String,
      default: null,
      description: "Property id",
    },
    property: {
      type: Object,
      default: null,
      description: "Object id",
    },
    filterContactableType: {
      type: String,
      default: null,
      description: "Contactable class",
    },
    filterContactableId: {
      type: String,
      default: null,
      description: "Contactable id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      contacts: [],
      loading: true,
      selectedOrganization: null,
      selectedLocations: null,
      selectedTags: [],
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterProperty: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedTags: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterContactableType: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterContactableId: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    calculateTotal(properties) {
      return properties.reduce((total, property) => {
        if (
          parseInt(property?.["auction_id"]) ===
          parseInt(this.property?.auction?.id)
        ) {
          total++;
        }
        return total;
      }, 0);
    },

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,tags,allowedLocations",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }

        if (this.filterProperty) {
          params = {
            ...params,
            filter: { ...params.filter, property: this.filterProperty },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.selectedTags) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              tags: this.selectedTags.map((item) => item.id),
            },
          };
        }
        if (this.filterContactableType) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              contactableType: this.filterContactableType,
            },
          };
        }
        if (this.filterContactableId) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              contactableId: this.filterContactableId,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        await this.$store.dispatch("contacts/list", params);
        this.contacts = this.$store.getters["contacts/list"];
        this.total = this.$store.getters["contacts/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editContact(customer) {
      this.$emit("onEditContact", customer);
      /* this.$router.push({
        name: "Edit Customer",
        params: { id: row.id },
      }); */
    },

    async deleteContact(id) {
      const confirmation = await swal.fire({
        title: this.$t("CONTACTS.DELETE_THIS_CONTACT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("contacts/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("CONTACTS.CONTACT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewContact(contact) {
      this.$emit("onViewContact", contact);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
