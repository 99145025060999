<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <el-select
        class="select-primary pagination-select"
        v-model="pagination.perPage"
        :placeholder="$t('COMMON.PER_PAGE')"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <organization-selector
        v-if="
          !filterOrganization &&
          $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        "
        @organizationChanged="
          (organizationId) => (selectedOrganization = organizationId)
        "
      />

      <base-input
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
        :placeholder="`${$t('COMMON.LOCATIONS')}`"
      >
        <locations-selector
          :locations="selectedLocations"
          @locationsChanged="(locations) => (selectedLocations = locations)"
        />
      </base-input>

      <base-input
        v-model="query"
        type="search"
        prepend-icon="fas fa-search"
        :placeholder="$t('COMMON.SEARCH')"
        clearable
      />

      <el-select
        :clearable="true"
        class="select-primary pagination-select"
        v-model="propertyStatus"
        :placeholder="$t('COMMON.STATUS')"
      >
        <el-option
          class="select-primary"
          :label="$t(`COMMON.ALL`)"
          :value="null"
        >
        </el-option>
        <el-option
          v-for="item in PROPERTY_STATUS"
          class="select-primary"
          :label="$t(`PROPERTIES.PROPERTIES_STATUS_${item}`)"
          :value="item"
          :key="item"
        >
        </el-option>
      </el-select>

      <el-select
        :clearable="true"
        class="select-primary pagination-select"
        v-model="propertyActive"
        :placeholder="$t('COMMON.STATUS')"
      >
        <el-option
          class="select-primary"
          :label="$t(`COMMON.ALL`)"
          :value="null"
        >
        </el-option>
        <el-option
          class="select-primary"
          :label="$t(`PROPERTIES.PROPERTIES_ACTIVE`)"
          :value="true"
        >
        </el-option>
        <el-option
          class="select-primary"
          :label="$t(`PROPERTIES.PROPERTIES_DISABLE`)"
          :value="false"
        >
        </el-option>
      </el-select>
    </div>
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="!loading ? properties : []"
          @sort-change="sortChange"
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" style="height: 100px; width: 100px" />
          </div>
          <el-table-column v-if="isSelectable">
            <div slot-scope="{ row }" class="table-actions">
              <base-checkbox
                v-if="row.sale_confirmed_at !== null"
                @input="toggleSelection(row.id)"
                :checked="isSelected(row.id)"
              ></base-checkbox>
            </div>
          </el-table-column>
          <el-table-column
            :label="$t('PROPERTIES.PROPERTY_NUMBER')"
            prop="property_number"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>
                {{ row.property_number ?? "" }}
              </span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.ADDRESS')" min-width="320">
            <template v-slot="{ row }">
              <span class="text-uppercase">
                {{ row?.address ?? "" }}, {{ row?.city ?? "" }},
                {{ row?.state ?? "" }}, {{ row?.zipcode ?? "" }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('PROPERTIES.BATCH_NUMBERS')"
            prop="batch_numbers"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span v-for="(item, idx) in row.batch_numbers" :key="idx">
                <template>
                  {{ `${$numberFormat(item.value)}` }}
                  {{ row.batch_numbers.length - 1 > idx ? ", " : " " }}
                </template>
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.STATUS')"
            prop="status"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <property-status-badge-component :property="row" />
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.UPDATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $formatDate(row.updated_at) }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.UPDATED_BY')" min-width="220">
            <template v-slot="{ row }">
              <users :users="[row.updatedBy]" />
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $formatDate(row.created_at) }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.CREATED_BY')" min-width="220">
            <template v-slot="{ row }">
              <users :users="[row.createdBy]" />
            </template>
          </el-table-column>

          <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_PROPERTIES)"
              >
                <a
                  type="text"
                  @click="viewProperty(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-expand-alt"></i>
                </a>
              </el-tooltip>

              <template
                v-if="
                  !row?.auction_is_closed ||
                  $currentUserCan(
                    $permissions.PERM_ACCESS_ALL_FIELDS_PROPERTIES
                  )
                "
              >
                <el-tooltip
                  :content="$t('COMMON.EDIT')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_EDIT_PROPERTIES)"
                >
                  <a
                    type="text"
                    @click="editProperty(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
              </template>

              <el-tooltip
                content="Delete"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_DELETE_PROPERTIES)"
              >
                <a
                  type="text"
                  @click="deleteProperty(row)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-trash-alt"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import Users from "@/components/Users.vue";
import { PROPERTY_STATUS } from "@/constants/properties";
import defaultProperty from "../defaultProperty";
import swal from "sweetalert2";
import moment from "moment";
import PropertyStatusBadgeComponent from "../components/PropertyStatusBadgeComponent";

export default {
  name: "property-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    LocationsSelector,
    PropertyStatusBadgeComponent,
    Users,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterAuctionId: {
      type: String,
      default: null,
      description: "Auction id",
    },
    isSelectable: {
      type: Boolean,
      default: false,
      description: "Check if table rows are selectable",
    },
    onlyConfirmed: {
      type: Boolean,
      default: false,
      description: "Only confirmed property",
    },
  },

  data() {
    const propertyData = this.$fillUserOrganizationData(defaultProperty);
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      PROPERTY_STATUS,
      propertyActive: null,
      propertyStatus: null,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      properties: [],
      loading: true,
      selectedOrganization: null,
      selectedLocations: propertyData.allowedLocations,
      selectedProperties: [],
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  created() {
    this.$root.$on("handleDataExport", ({ onlyConfirmed }) => {
      console.log("onlyConfirmed in on", onlyConfirmed);
      this.getExportDataToExcel(onlyConfirmed);
    });
  },

  beforeDestroy() {
    this.$root.$off("handleDataExport");
  },

  watch: {
    onlyConfirmed(value) {},
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    propertyActive: {
      handler: "getListDebounced",
      immediate: true,
    },
    propertyStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterAuctionId: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getExportDataToExcel(onlyConfirmed = false) {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { search: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
        include: "organization,allowedLocations,updatedBy,createdBy",
      };

      if (this.filterOrganization) {
        params = {
          ...params,
          filter: { ...params.filter, organization: this.filterOrganization },
        };
      }
      if (this.selectedOrganization) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            organization: this.selectedOrganization,
          },
        };
      }
      if (this.propertyActive === false || this.propertyActive === true) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            active: this.propertyActive,
          },
        };
      }
      if (this.propertyStatus) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            status: this.propertyStatus,
          },
        };
      }

      if (onlyConfirmed) {
        console.log("in params", onlyConfirmed);
        params = {
          ...params,
          filter: {
            ...params.filter,
            onlyConfirmed: onlyConfirmed,
          },
        };
      }

      if (this.filterAuctionId) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            auction: this.filterAuctionId,
          },
        };
      }

      if (this.selectedLocations) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            allowedLocations: this.selectedLocations.map((loc) => loc.id),
          },
        };
      }
      swal.fire({
        title: this.$t(`COMMON.LOADING`),
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const response = await this.$store.dispatch(
          "properties/exportExcel",
          params
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.$t("PROPERTIES.EXPORT_FILE_NAME_OF") +
            moment().format("DD/MM/YYYY_HH_mm_ss") +
            ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        if (error.response?.status === 403) {
          await this.showRequestError(error);
          this.$notify({
            type: "warning",
            message: this.$t("ERRORS.UNAUTHORIZED_ACTION"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      }
      swal.close();
    },

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,allowedLocations,updatedBy,createdBy",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.filterAuctionId) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              auctionId: this.filterAuctionId,
            },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.propertyActive === false || this.propertyActive === true) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              active: this.propertyActive,
            },
          };
        }
        if (this.propertyStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.propertyStatus,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        await this.$store.dispatch("properties/list", params);
        this.properties = this.$store.getters["properties/list"];
        this.total = this.$store.getters["properties/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteProperty(property) {
      this.$emit("onDeleteProperty", property);
    },

    viewProperty(property) {
      this.$emit("onViewProperty", property);
    },

    editProperty(property) {
      this.$emit("onEditProperty", property);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
    toggleSelection(propertyId) {
      if (this.selectedProperties.includes(propertyId)) {
        this.selectedProperties = this.selectedProperties.filter(
          (id) => id !== propertyId
        );
      } else {
        this.selectedProperties.push(propertyId);
      }
      this.$emit("onSelectedProperties", this.selectedProperties);
    },
    isSelected(propertyId) {
      return this.selectedProperties.includes(propertyId);
    },
  },
};
</script>
