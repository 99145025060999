<template>
  <div class="container-fluid">
    <div slot="header" class="form-header">
      <h3>{{ $t("PROPERTIES.EDIT_PROPERTY") }}</h3>
    </div>
    <property-form
      :loading="loading"
      :propertyData="property"
      :formErrors="formErrors"
      @propertySubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import PropertyForm from "../partials/PropertyForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import defaultProperty from "../defaultProperty";

export default {
  layout: "DashboardLayout",

  components: { PropertyForm },

  mixins: [alertLeave, requestErrorMixin],

  props: {
    propertyId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      property: cloneDeep(defaultProperty),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("properties/get", this.propertyId);
        this.property = this.$store.getters["properties/property"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(property) {
      this.loading = true;
      const propertyData = cloneDeep(property);

      try {
        await this.$store.dispatch("properties/update", propertyData);
        this.$notify({
          type: "success",
          message: this.$t("PROPERTIES.PROPERTY_UPDATED"),
        });
        const property = await this.$store.getters["properties/property"];
        this.$emit("onViewProperty", property, true);
        this.loading = false;
      } catch (error) {
        if (error?.response?.status === 403) {
          await this.showRequestError(error);
        } else if (error.response.status === 400) {
          await this.showRequestError(error);
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.formErrors = error.response.data.errors;
        }
        this.loading = false;
      }
    },
  },
};
</script>
