<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header mb-3">
      <h3>{{ $t("COMMON.BIDS") }}</h3>
      <div class="d-flex align-items-center">
        <label class="mr-3 mb-4"> {{ $t("BIDS.BID_FOR") }} </label>
        <base-input
          :input-group-classes="'mb-0'"
          :input-classes="'mb-0'"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <user-selector
            :placeholder="$t('BIDS.BID_USER')"
            :filterable="true"
            :showAll="false"
            :filterOrganization="property.organization.id"
            @userChanged="
              (userId) => {
                bid.user.id = userId;
                checkUser(userId);
              }
            "
          />
        </base-input>
      </div>
    </div>
    <div class="d-flex">
      <div class="d-flex">
        <h4>{{ $t("BIDS.STARTING_BID_AMOUNT") }}</h4>
        <span class="font-weight-800 text-primary ml-2 h4">{{
          $formatCurrency(property.bid_starting_amount)
        }}</span>
      </div>
      <span class="ml-3 mr-3">|</span>
      <div class="d-flex">
        <h4>{{ $t("BIDS.ALLOWED_BID_STEP") }}</h4>
        <span class="font-weight-800 text-primary ml-2 h4">{{
          $formatCurrency(allowedBidStep)
        }}</span>
      </div>
      <span class="ml-3 mr-3">|</span>
      <div class="d-flex">
        <h4>{{ $t("BIDS.BEST_OFFER") }}</h4>
        <span class="font-weight-800 text-danger ml-2 h4">{{
          $formatCurrency(bestOffer)
        }}</span>
      </div>
    </div>
    <hr class="mb-5 mt-3" />
    <el-alert
      :title="$t('BIDS.INFO')"
      type="warning"
      :description="$t('BIDS.INFO_TEXT')"
      show-icon
    >
    </el-alert>
    <div class="row mt-4">
      <div class="col-sm-12">
        <el-form label-width="150px" label-position="left" :inline="true">
          <el-form-item :label="$t('BIDS.PLACE_BET')">
            <el-input
              v-model="bid.bid"
              :disabled="!bid.user.id || hasMaxBid"
            ></el-input>
          </el-form-item>
          <el-button
            @click="addBidStep()"
            :disabled="!bid.user.id || hasMaxBid"
            type="primary"
            >+ {{ $formatCurrency(allowedBidStep) }}</el-button
          >
          <el-button
            @click="addBidStepx2()"
            :disabled="!bid.user.id || hasMaxBid"
            type="primary"
            >+ {{ $formatCurrency(allowedBidStep * 2) }}</el-button
          >
          <el-button
            @click="addBidStepx3()"
            :disabled="!bid.user.id || hasMaxBid"
            type="primary"
            >+ {{ $formatCurrency(allowedBidStep * 3) }}</el-button
          >
        </el-form>
      </div>
      <div class="col-sm-12">
        <el-form label-width="150px" label-position="left" :inline="true">
          <el-form-item class="mb-0" :label="$t('BIDS.MAX_BID')">
            <el-input
              v-model="bid.max_bid"
              :disabled="!bid.user.id"
              @input="setMaxBid"
            ></el-input>
          </el-form-item>
          <el-button
            @click="cancelMaxBid()"
            :disabled="!bid.user.id"
            type="danger"
            >{{ $t("BIDS.CANCEL_MAX_BID") }}</el-button
          >
        </el-form>
      </div>
    </div>
    <div class="col-sm-12 mb-3">
      <el-button
        @click="submitBid()"
        :disabled="!bid.user.id || loading"
        type="success"
        >{{ $t("BIDS.TAKE_BID") }}</el-button
      >
    </div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :row-class-name="tableRowClassName"
            :data="!loading ? bids : []"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>
            <el-table-column :label="$t('BIDS.BID_USER')" min-width="220">
              <template v-slot="{ row }">
                <span>
                  {{ row.user.firstname ?? "" }} {{ row.user.lastname ?? "" }}
                </span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('BIDS.BID')" min-width="120">
              <template v-slot="{ row }">
                <span>
                  {{ $formatCurrency(row.bid) }}
                </span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('BIDS.MAX_BID')" min-width="120">
              <template v-slot="{ row }">
                <span v-if="row.max_bid !== null && row.max_bid !== '0.00'">
                  {{ $formatCurrency(row.max_bid) }}
                </span>
                <span v-else>-</span>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('BIDS.LAST_UPDATE')"
              prop="updatedAt"
              min-width="200"
            >
              <template v-slot="{ row }">
                <span>
                  {{ $formatDate(row.updatedAt, "LLL") }} -
                  {{ fromToday(row.updatedAt) }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Alert,
  Form,
  Input,
  FormItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import defaultProperty from "../defaultProperty";
import UserSelector from "@/components/UserSelector.vue";
import defaultBid from "../defaultBid";
import { cloneDeep } from "lodash";
import Pusher from "pusher-js";
import swal from "sweetalert2";
import moment from "moment";

export default {
  name: "property-view-bids",

  components: {
    UserSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Alert.name]: Alert,
    [Input.name]: Input,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    property: {
      type: Object,
      default: null,
      description: "Property object",
    },
  },

  data() {
    return {
      query: null,
      sort: "-bid",
      bids: [],
      loading: false,
      itemsFormError: null,
      bid: cloneDeep(defaultBid),
      hasMaxBid: false,
    };
  },

  computed: {
    allowedBidStep() {
      if (!this.bids || this.bids.length === 0) {
        const propertyBidStartingAmount = parseFloat(
          this.property.bid_starting_amount
        );
        const allowedBidStep = this.property.bidSteps.find((bidStep) => {
          return (
            propertyBidStartingAmount >= bidStep.amount_min &&
            propertyBidStartingAmount <= bidStep.amount_max
          );
        });

        return allowedBidStep ? allowedBidStep.bid_amount : null;
      } else {
        const highestBid = Math.max(...this.bids.map((bid) => bid.bid));

        const allowedBidStep = this.property.bidSteps.find((bidStep) => {
          return (
            highestBid >= bidStep.amount_min &&
            (bidStep.amount_max === null || highestBid <= bidStep.amount_max)
          );
        });

        return allowedBidStep ? allowedBidStep.bid_amount : null;
      }
    },

    bestOffer() {
      if (!this.bids || this.bids.length === 0) {
        return this.property.bid_starting_amount;
      } else {
        return Math.max(
          ...this.bids.map((bid) =>
            bid.bid !== null ? bid.bid : this.property.bid_starting_amount
          )
        );
      }
    },
  },

  created() {},

  mounted() {
    const pusher = new Pusher("c1ed129685e55841a4f5", {
      cluster: "eu",
    });

    const channel = pusher.subscribe("bids");
    channel.bind(
      "new-bid-event",
      function (data) {
        this.getListDebounced();
      }.bind(this)
    );
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    property: {
      handler: "getListDebounced",
      immediate: true,
    },
    bestOffer: {
      immediate: true,
      handler(newBestOffer) {
        this.updateBid(
          parseFloat(newBestOffer),
          parseFloat(this.allowedBidStep)
        );
      },
    },
    allowedBidStep(newAllowedBidStep) {
      this.updateBid(parseFloat(this.bestOffer), parseFloat(newAllowedBidStep));
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          include: "user",
        };

        if (this.property) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              propertyId: this.property.id,
            },
          };
        }

        await this.$store.dispatch("bids/list", params);
        this.bids = this.$store.getters["bids/list"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    setMaxBid() {
      this.bid.bid = "";
      this.hasMaxBid = true;
    },

    cancelMaxBid() {
      this.updateBid(
        parseFloat(this.bestOffer),
        parseFloat(this.allowedBidStep)
      );
      this.hasMaxBid = false;
      this.bid.max_bid = "";
    },

    updateBid(bestOffer, allowedBidStep) {
      if (!isNaN(bestOffer) && !isNaN(allowedBidStep)) {
        this.bid.bid = bestOffer + allowedBidStep;
      } else {
        this.bid.bid = null;
      }
    },

    fromToday(date) {
      return moment(date).fromNow();
    },

    async submitBid() {
      this.loading = true;
      const amountAdded = parseFloat(this.bid.bid) - parseFloat(this.bestOffer);
      if (
        !(amountAdded % this.allowedBidStep === 0) &&
        (!this.bid.max_bid || this.max_bid === 0)
      ) {
        this.$notify({
          type: "danger",
          message: "Mise non autorisée. Montant incorrect",
        });
        this.loading = false;
      } else {
        try {
          this.bid.property = this.property;
          let me = await this.$store.getters["profile/me"];
          this.bid.createdBy.id = me.id;

          await this.$store.dispatch("bids/list", {
            filter: {
              userId: this.bid.user.id,
              propertyId: this.bid.property.id,
            },
          });

          const existingBids = this.$store.getters["bids/list"];

          if (existingBids.length > 0) {
            const existingBid = existingBids[0];
            await this.$store.dispatch("bids/get", existingBid.id);

            const bidData = this.$store.getters["bids/bid"];
            bidData.bid = this.bid.bid;
            bidData.max_bid = this.bid.max_bid;
            bidData.createdBy.id = me.id;

            await this.$store.dispatch("bids/update", bidData);
          } else {
            await this.$store.dispatch("bids/add", this.bid);
          }

          this.$notify({
            type: "success",
            message: this.$t("COMMON.ITEM_ADDED"),
          });

          this.loading = false;
        } catch (error) {
          if (error?.response?.status === 500) {
            this.$notify({
              type: "danger",
              message: "Erreur de connexion avec le serveur",
            });
          } else if (
            error?.response?.status === 403 ||
            error?.response?.status === 400
          ) {
            this.itemsFormError = error.response.data.errors;
            swal.fire({
              title: this.itemsFormError[0].detail,
              icon: "error",
              confirmButtonText: this.$t("COMMON.OK"),
            });
          } else {
            this.itemsFormError = error.response.data.errors;
            this.loading = false;
            this.$notify({
              type: "danger",
              message: this.itemsFormError[0].detail,
            });
          }
          this.loading = false;
        }
      }
    },

    tableRowClassName({ row }) {
      if (row.user.id === this.bid.user.id) {
        return "bg-gradient-success row-success";
      }
      return "";
    },

    addBidStep() {
      this.bid.bid = parseFloat(this.bid.bid) + parseFloat(this.allowedBidStep);
    },

    addBidStepx2() {
      this.bid.bid =
        parseFloat(this.bid.bid) + parseFloat(this.allowedBidStep * 2);
    },

    addBidStepx3() {
      this.bid.bid =
        parseFloat(this.bid.bid) + parseFloat(this.allowedBidStep * 3);
    },

    async checkUser(userId) {
      this.updateBid(
        parseFloat(this.bestOffer),
        parseFloat(this.allowedBidStep)
      );

      await this.$store.dispatch("bids/list", {
        filter: {
          userId: userId,
          propertyId: this.property.id,
        },
      });

      const existingBids = this.$store.getters["bids/list"];

      if (existingBids.length > 0) {
        const existingBid = existingBids[0];

        if (
          parseFloat(existingBid.max_bid) === 0 ||
          existingBid.max_bid === null
        ) {
          this.hasMaxBid = false;
          this.bid.max_bid = "";
        } else {
          this.setMaxBid();
          this.bid.max_bid = existingBid.max_bid;
        }
      } else {
        this.hasMaxBid = false;
        this.bid.max_bid = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row-success {
  background: #fdf5e6 !important;
}
</style>
