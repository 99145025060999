<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ property.address }},
      <span class="text-uppercase">
        {{ property.city }} {{ property.state }} </span
      >,
      {{ property.zipcode }}
    </h3>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TAXABLE") }}</dt>
          <dd class="col-sm-8" v-if="property.taxable !== undefined">
            <span v-if="property.taxable">
              {{ $t("COMMON.YES") }}
            </span>
            <span v-else>
              {{ $t("COMMON.NO") }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.ACQUISITION_NUMBER") }}</dt>
          <dd class="col-sm-8">
            {{ property.acquisition_number }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.ACQUISITION_METHODE") }}</dt>
          <dd class="col-sm-8">
            {{ property.acquisition_method }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.PROPERTY_NUMBER") }}</dt>
          <dd class="col-sm-8">
            {{ property.property_number }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.DISPLAY_ON_WEB_SITE") }}</dt>
          <dd class="col-sm-8">
            <badge :type="'success'" v-if="property.active">
              {{ $t(`COMMON.YES`) }}
            </badge>
            <badge :type="'danger'" v-else>
              {{ $t(`COMMON.NO`) }}
            </badge>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8" v-if="property.status">
            <property-status-badge-component :property="property" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.CANCEL_REASON") }}</dt>
          <dd class="col-sm-8" v-if="property?.cancel_reason">
            {{ property?.cancel_reason }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.SOLD_AMOUNT") }}</dt>
          <dd class="col-sm-8" v-if="property.sold_amount">
            {{ $formatCurrency(property.sold_amount) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.BID_STARTING_AMOUNT") }}</dt>
          <dd class="col-sm-8" v-if="property.bid_starting_amount">
            {{ $formatCurrency(property.bid_starting_amount) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.SOLD_AT") }}</dt>
          <dd v-if="property.sold_at" class="col-sm-8">
            {{ $formatDate(property.sold_at, "dddd D MMMM YYYY") }}
          </dd>
          <dd v-else class="col-sm-8">
            <span v-if="property?.auction?.start_at">
              {{ $formatDate(property?.auction?.start_at, "LLL") }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.SALE_CONFIRMED_AT") }}</dt>
          <dd class="col-sm-8" v-if="property.sale_confirmed_at">
            {{
              $formatDate(property.sale_confirmed_at, "dddd D MMMM YYYY HH:mm")
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("PROPERTIES.PROPERTIES_TAXES_DUE_SCHOOL") }}
          </dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(property.owed_taxes_school_board) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("PROPERTIES.PROPERTIES_TAXES_DUE_MUNICIPAL") }}
          </dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(property.owed_taxes_municipality) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.PROPERTY_TAX_DUE") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(property.total_taxes) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.MRC_FEES") }}</dt>
          <dd class="col-sm-8" v-if="property.mrc_fees">
            {{ $formatCurrency(property.mrc_fees) }}
          </dd>
        </dl>

        <dl class="row d-none">
          <dt class="col-sm-4">{{ $t("COMMON.SUBTOTAL") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(property.sub_total) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.PROPERTY_TOTAL_DUE") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(property.total) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.REGISTRATION_NUMBER") }}</dt>
          <dd class="col-sm-8">
            {{ property.registration_number }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.BATCH_NUMBER") }}</dt>
          <dd class="col-sm-8">
            <span v-for="(item, idx) in property.batch_numbers" :key="idx">
              <template>
                {{ `${$numberFormat(item.value)}` }}
                {{ property.batch_numbers.length - 1 > idx ? ", " : " " }}
              </template>
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.TYPE") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{
                $t(`PROPERTIES.PROPERTIES_TYPE_LONG_${property.property_type}`)
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8 text-uppercase">
            <div v-html="property.address"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8">
            <div v-html="property.city"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            <div v-html="property.zipcode"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8 text-uppercase">
            <div v-html="property.state"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8 text-uppercase">
            <div v-html="property.country"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES.DESIGNATION") }}</dt>
          <dd class="col-sm-8">
            {{ property.designation }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NOTE") }}</dt>
          <dd class="col-sm-8">
            <div v-html="property.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_BY") }}</dt>
          <dd class="col-sm-8">
            <users :users="[property.createdBy]" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_BY") }}</dt>
          <dd class="col-sm-8">
            <users :users="[property.updatedBy]" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="property.allowedLocations" />
          </dd>
        </dl>

        <dl class="row" v-if="0">
          <dt class="col-sm-4">{{ $t("COMMON.OWNERS") }}</dt>
          <dd class="col-sm-8">
            <contacts :contacts="property.owners" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="property.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(property.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(property.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { TAX_TYPE_AMOUNT, TAX_TYPE_PERCENTAGE } from "@/constants/taxes";
import Users from "@/components/Users.vue";
import Contacts from "@/components/Contacts.vue";
import PropertyStatusBadgeComponent from "../components/PropertyStatusBadgeComponent";

export default {
  name: "property-view-global",

  props: ["property"],

  components: {
    Users,
    Contacts,
    PropertyStatusBadgeComponent,
  },

  data() {
    return { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT };
  },

  computed: {},

  created() {},

  methods: {
    propertyUpdated() {
      this.$emit("propertyUpdated", true);
    },
  },

  mounted() {},

  watch: {
    property(property) {},
  },
};
</script>
