<template>
  <div class="elite-tabs-wrapper-content">
    <div class="mb-2">
      <h3>
        {{ $t("PROPERTIES.PAYMENT_DETAILS") }}
      </h3>
    </div>
    <div class="custom-header">
      <div class="mt-2">
        {{ $t("PROPERTIES.PROPERTY_TOTAL_DUE") }} :
        {{ $formatCurrency(property.total) }}
      </div>
      <base-button
        type="button"
        @click="toggleTransactionModal"
        v-if="
          !loading &&
          !property.transactions?.length &&
          parseInt(property?.total) &&
          $currentUserCan($permissions.PERM_CREATE_TRANSACTIONS_PROPERTIES)
        "
        :disabled="property.transactions?.length > 0"
        class="elite-button add"
      >
        <span class="btn-inner--icon">
          <i class="far fa-plus-circle"></i>
        </span>
        <span class="btn-inner--text">
          {{ $t("PROPERTY_TRANSACTIONS.ADD_TRANSACTION") }}
        </span>
      </base-button>

      <div v-if="property.transactions?.length">
        <base-button
          type="button"
          @click="editTransactions"
          v-if="
            property.transactions?.length &&
            $currentUserCan($permissions.PERM_DELETE_TRANSACTIONS_PROPERTIES)
          "
          :disabled="!property.transactions?.length || loading"
          class="elite-button btn-default"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          <span v-else class="btn-inner--icon">
            <i class="far fa-edit"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("COMMON.EDIT") }}
          </span>
        </base-button>
        <base-button
          type="button"
          @click="deleteTransactions"
          v-if="
            property.transactions?.length &&
            $currentUserCan($permissions.PERM_DELETE_TRANSACTIONS_PROPERTIES)
          "
          :disabled="!property.transactions?.length || loading"
          class="elite-button btn-danger"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          <span v-else class="btn-inner--icon">
            <i class="far fa-trash-alt"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("COMMON.DELETE") }}
          </span>
        </base-button>

        <base-button
          type="button"
          @click="print"
          :disabled="!property.transactions?.length || loading"
          class="elite-button add"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          <span v-else class="btn-inner--icon">
            <i class="far fa-print"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("PROPERTIES.PRINT_RECEIPT") }}
          </span>
        </base-button>
      </div>
    </div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="property?.transactions"
          >
            <el-table-column :label="$t('COMMON.AMOUNT')" min-width="30">
              <template v-slot="{ row }">
                {{ $formatCurrency(row.amount) }}
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('PROPERTY_TRANSACTIONS.TRANSACTION_TYPE')"
              min-width="30"
            >
              <template v-slot="{ row }">
                <badge
                  type="success"
                  v-if="row?.transaction_type === TRANSACTION_TYPE_PAYMENT"
                >
                  {{ $t(`PROPERTY_TRANSACTIONS.TRANSACTION_TYPE_PAYMENT`) }}
                </badge>
                <badge
                  type="danger"
                  v-if="row?.transaction_type === TRANSACTION_TYPE_REFUND"
                >
                  {{ $t(`PROPERTY_TRANSACTIONS.TRANSACTION_TYPE_REFUND`) }}
                </badge>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('PROPERTY_TRANSACTIONS.TRANSACTION_METHODE')"
              min-width="30"
            >
              <template v-slot="{ row }">
                {{ $t(`AUCTIONS.PAYMENT_SOURCE_${row?.method_payment}`) }}
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('PROPERTY_TRANSACTIONS.TRANSACTION_DATE')"
              min-width="30"
              v-if="0"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row?.transaction_date) }}
              </template>
            </el-table-column>

            <!--
            <el-table-column min-width="10">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip :content="$t('COMMON.EDIT')" placement="top">
                  <a
                      type="text"
                      @click="openBidStepModal(row)"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
            -->
          </el-table>
        </div>
        <div class="row mt-4" v-if="property.transactions?.length">
          <div>
            <h3>
              {{ $t("PROPERTIES.PAYMENT_INFOS") }}
            </h3>
          </div>
          <div class="col-12 mt--4">
            <dl class="row">
              <dt class="col-sm-4">{{ $t("COMMON.TO_THE_ATTENTION_OF") }}</dt>
              <dd class="col-sm-8">
                {{ property.customer }}
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4">{{ $t("PROPERTIES.TRANSACTION_DATE") }}</dt>
              <dd class="col-sm-8">
                {{ $formatDate(property.transaction_date, "LL") }}
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4">
                {{ $t("PROPERTIES.PAYMENT_RECEIVED_BY") }}
              </dt>
              <dd class="col-sm-8">
                <users
                  :users="
                    property.paymentReceivedBy
                      ? [property.paymentReceivedBy]
                      : []
                  "
                />
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4">{{ $t("COMMON.NOTE") }}</dt>
              <dd class="col-sm-8">
                {{ property.transaction_excerpt }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <property-transaction-form
      :showModal="showModal"
      :property="property"
      :refreshView="refreshView"
      :transactions="propertyTransactions"
      @onToggleModal="toggleTransactionModal"
    />
  </div>
</template>

<script>
import { Button, Table, TableColumn, Tooltip } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  TRANSACTION_TYPE_PAYMENT,
  TRANSACTION_TYPE_REFUND,
} from "@/constants/properties";
import PropertyTransactionForm from "./PropertyTransactionForm";
import Users from "@/components/Users.vue";
import swal from "sweetalert2";

export default {
  name: "property-transaction-table",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    PropertyTransactionForm,
    Users,
  },

  mixins: [formMixin, requestErrorMixin],

  props: {
    filterAuctionId: {
      type: String,
      default: null,
      description: "Auction id",
    },
    property: {
      type: Object,
      default: null,
      description: "Property",
    },
    refreshView: {
      type: Function,
      default: null,
      description: "View refresh after some operation",
    },
  },

  data() {
    return {
      TRANSACTION_TYPE_PAYMENT,
      TRANSACTION_TYPE_REFUND,
      propertyTransactions: [],
      loading: false,
      showModal: false,
    };
  },

  computed: {},

  methods: {
    openBidStepModal(bidStep) {
      this.$emit("onOpenBidStepModal", bidStep);
    },

    toggleTransactionModal() {
      this.showModal = !this.showModal;
    },

    getListDebounced: _.debounce(function () {}, 300),

    async print() {
      this.loading = true;
      swal.fire({
        title: this.$t("COMMON.LOADING"),
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "properties/print",
          this.property.id
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url, "_blank");
        swal.close();
        this.loading = false;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
        swal.close();
        this.loading = false;
      }
    },

    async deleteTransactions() {
      const confirmation = await swal.fire({
        title: this.$t("PROPERTIES.DELETE_TRANSACTION_ON_PROPERTY"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.handleTransactionDeleting();
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editTransactions() {
      this.showModal = true;
    },

    async handleTransactionDeleting() {
      this.loading = true;
      await this.$store
        .dispatch("properties/destroyTransaction", this.property?.id)
        .then(() => {
          this.$notify({
            type: "success",
            message: this.$t("PROPERTY_TRANSACTIONS.TRANSACTION_DELETED"),
          });
          this.loading = false;
          this.refreshView();
        })
        .catch((error) => {
          this.loading = false;
          if (error?.response?.status === 400) {
            this.showRequestError(error);
          } else if (error?.response?.status === 403) {
            this.showRequestError(error);
          } else {
            this.$notify({
              type: "danger",
              message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
            });
          }
        });
    },
  },

  mounted() {
    this.$parent.$on("propertyTransactionsUpdated", this.getListDebounced);
  },

  beforeDestroy() {
    this.$parent.$off("propertyTransactionsUpdated", this.getListDebounced);
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
};
</script>
